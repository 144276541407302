import { CSS } from '@konta/ui';
import dayjs from 'dayjs';
import {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import { useQueryClient } from 'react-query';
import usePutTaxableEntityFlow from 'shared/hooks/usePutTaxableEntityFlow';
import useTaxableEntityFlows from 'shared/hooks/useTaxableEntityFlows';
import {
  TaxableEntityFlow,
  TaxableEntityFlowStep,
  TaxableEntityFlowUpdatePayload,
} from 'types/flows';

interface InsightsDialogContextType {
  isOpen: boolean;
  currentFlowIndex: number;
  currentSlideIndex: number;
  taxableEntityFlows: TaxableEntityFlow[];
  sortedTaxableEntityFlows: TaxableEntityFlow[];
  taxableEntityFlowsLoading: boolean;
  putTaxableEntityFlowLoading: boolean;
  isLoading: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleFlowSelect: (flowIndex: number) => void;
  handleFlowChange: (newFlowIndex: number) => void;
  handleSlideChange: (newSlideIndex: number) => void;
  onFlowVisit?: (flow: TaxableEntityFlow, flowIndex: number) => void;
  onStepVisit?: (
    step: TaxableEntityFlowStep,
    slideIndex: number,
    flow: TaxableEntityFlow,
    flowIndex: number,
  ) => void;
  onFlowComplete?: (flow: TaxableEntityFlow, flowIndex: number) => void;
  isActiveDialog: boolean;
  setIsActiveDialog: (isActiveDialog: boolean) => void;
  setPreviewContainerCss: (css: CSS) => void;
  previewContainerCss: CSS;
  closeDialog: () => void;
}

const InsightsDialogContext = createContext<
  InsightsDialogContextType | undefined
>(undefined);

export function InsightsDialogProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const initialDate = useRef(dayjs());
  const { taxableEntityFlows, refetch, taxableEntityFlowsLoading } =
    useTaxableEntityFlows();
  const [sortedTaxableEntityFlows, setSortedTaxableEntityFlows] = useState<
    TaxableEntityFlow[]
  >([]);

  const queryClient = useQueryClient();
  const putTaxableEntityFlow = usePutTaxableEntityFlow();
  const [previewContainerCss, setPreviewContainerCss] = useState({});
  const [isActiveDialog, setIsActiveDialog] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [currentFlowIndex, setCurrentFlowIndex] = useState(-1);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(-1);

  const handleFlowSelect = useCallback((flowIndex: number) => {
    setCurrentFlowIndex(flowIndex);
    setCurrentSlideIndex(0);
    setIsOpen(true);
  }, []);

  const handleFlowChange = useCallback((newFlowIndex: number) => {
    setCurrentFlowIndex(newFlowIndex);
    setCurrentSlideIndex(0);
  }, []);

  const handleSlideChange = useCallback((newSlideIndex: number) => {
    setCurrentSlideIndex(newSlideIndex);
  }, []);

  const closeDialog = useCallback(() => {
    setIsOpen(false);
    setIsActiveDialog(true);
    setPreviewContainerCss({});
    setCurrentFlowIndex(-1);
    setCurrentSlideIndex(-1);
  }, []);

  const onFlowVisit = useCallback(
    (taxableEntityFlow: TaxableEntityFlow, flowIndex: number) => {
      // console.log('Flow visited', taxableEntityFlow, flowIndex);
    },
    [],
  );

  const onStepVisit = useCallback(
    (
      step: TaxableEntityFlowStep,
      slideIndex: number,
      taxableEntityFlow: TaxableEntityFlow,
      flowIndex: number,
    ) => {
      const mutatePayload: TaxableEntityFlowUpdatePayload = {
        taxableEntityFlowId: taxableEntityFlow.id,
        payload: {},
      };

      const stepNeedsUpdate = !step.seen || !step.completed;

      if (stepNeedsUpdate) {
        mutatePayload.payload.steps_attributes = [
          {
            id: step.id,
            seen: true,
            completed: true,
          },
        ];
      }

      const allStepsCompleted = taxableEntityFlow.steps.every(
        (s) => s.completed || s.id === step.id,
      );

      let newStatus = taxableEntityFlow.status;
      if (allStepsCompleted && taxableEntityFlow.status !== 'completed') {
        newStatus = 'completed';
      } else if (taxableEntityFlow.status === 'unbegun') {
        newStatus = 'in_progress';
      }

      if (newStatus !== taxableEntityFlow.status) {
        mutatePayload.payload.status = newStatus;
      }

      if (stepNeedsUpdate || newStatus !== taxableEntityFlow.status) {
        putTaxableEntityFlow.mutate(mutatePayload, {
          onSuccess: (updatedFlow) => {
            void refetch();
            /*  queryClient.setQueryData<TaxableEntityFlow[]>(
              [TAXABLE_ENTITY_FLOWS],
              (oldData) => {
                if (!oldData) return [];
                return oldData.map((flow) =>
                  flow.id === updatedFlow.id ? updatedFlow : flow,
                );
              },
            ); */
          },
        });
      }
    },
    [putTaxableEntityFlow, refetch],
  );

  const onFlowComplete = useCallback(
    (flow: TaxableEntityFlow, flowIndex: number) => {
      // console.log('Flow complete', flow, flowIndex);
    },
    [],
  );

  useEffect(() => {
    if (taxableEntityFlows.length > 0) {
      const sorted = [...taxableEntityFlows].sort((a, b) => {
        const aOldCompleted =
          a.status === 'completed' &&
          dayjs(a.updated_at).isBefore(initialDate.current);
        const bOldCompleted =
          b.status === 'completed' &&
          dayjs(b.updated_at).isBefore(initialDate.current);

        if (aOldCompleted && !bOldCompleted) return 1;
        if (!aOldCompleted && bOldCompleted) return -1;
        return a.id - b.id;
      });

      setSortedTaxableEntityFlows(sorted);
    }
  }, [taxableEntityFlows]);

  const value = useMemo(
    () => ({
      isOpen,
      currentFlowIndex,
      currentSlideIndex,
      taxableEntityFlows,
      setIsOpen,
      handleFlowChange,
      handleSlideChange,
      onFlowVisit,
      onStepVisit,
      onFlowComplete,
      handleFlowSelect,
      isActiveDialog,
      setIsActiveDialog,
      setPreviewContainerCss,
      previewContainerCss,
      closeDialog,
      sortedTaxableEntityFlows,
      taxableEntityFlowsLoading,
      putTaxableEntityFlowLoading: putTaxableEntityFlow.isLoading,
      isLoading: taxableEntityFlowsLoading || putTaxableEntityFlow.isLoading,
    }),
    [
      isOpen,
      currentFlowIndex,
      currentSlideIndex,
      taxableEntityFlows,
      handleFlowChange,
      handleSlideChange,
      onFlowVisit,
      onStepVisit,
      onFlowComplete,
      handleFlowSelect,
      isActiveDialog,
      previewContainerCss,
      closeDialog,
      sortedTaxableEntityFlows,
      taxableEntityFlowsLoading,
      putTaxableEntityFlow.isLoading,
    ],
  );

  return (
    <InsightsDialogContext.Provider value={value}>
      {children}
    </InsightsDialogContext.Provider>
  );
}

export const useInsightsDialog = () => {
  const context = useContext(InsightsDialogContext);
  if (context === undefined) {
    throw new Error(
      'useInsightsDialog must be used within an InsightsDialogProvider',
    );
  }
  return context;
};
