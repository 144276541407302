import { useMemo } from 'react';
import dayjs from 'dayjs';
import { Chip } from '@konta/ui';
import { kontaStatusChip } from 'shared/constants/kontaStatus';
import ScoreGraph from 'shared/components/ScoreGraph';
import { ScoreFlowStep } from 'types/flows';
import {
  ScoreContainer,
  ScoreGraphContainer,
  Subtitle,
  Title,
  UpdateText,
} from './styled';

interface ScoreStepContentProps {
  scoreFlowStep: ScoreFlowStep;
}

export default function ScoreStepContent({
  scoreFlowStep,
}: ScoreStepContentProps) {
  const { state } = scoreFlowStep;
  const kontaScore = state;

  const kontaScoreData = useMemo(() => {
    const lastUpdated = kontaScore?.last_update
      ? dayjs(kontaScore.last_update).format('DD/MM/YYYY')
      : '-';
    const status = kontaScore?.status || 'No disponible';
    const score = kontaScore?.score || 0;
    const chipColor = kontaStatusChip[status];
    return {
      lastUpdated,
      status,
      score,
      chipColor,
    };
  }, [kontaScore]);

  return (
    <>
      <Title>Tu calificación es:</Title>
      <ScoreContainer>
        <Chip color={kontaScoreData.chipColor} label={kontaScoreData.status} />
      </ScoreContainer>
      <ScoreGraphContainer>
        <ScoreGraph
          strokeWidth={19}
          score={kontaScoreData.score}
          size={270}
          fontCss={{ fontSize: '44px' }}
        />
      </ScoreGraphContainer>
      <UpdateText>
        Última actualización: {kontaScoreData.lastUpdated}
      </UpdateText>
      <Subtitle>
        <span>¿A que se debe esto?</span>
        <span>te explicamos 👉🏼</span>
      </Subtitle>
    </>
  );
}
