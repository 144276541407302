import { Section, Carousel, Flex } from '@konta/ui';
import { TaxableEntityFlow } from 'types/flows';
import Loader from 'shared/components/Loader';
import Navigation from './Navigation';
import Subtitle from './Subtitle';
import InsightsDialog from './InsightsDialog';
import useInsights from './useInsights';
import { slideStyles, seenCss, unseenCss } from './styled';

export default function Insights() {
  const {
    carouselRef,
    buttonState,
    handleExternalPrev,
    handleExternalNext,
    isMobileOrTablet,
    currentMonth,
    handleFlowSelect,
    carouselItems,
    taxableEntityFlowsLoading,
  } = useInsights();
  if (!carouselItems.length && !taxableEntityFlowsLoading) return null;
  return (
    <>
      <InsightsDialog />
      <Section
        data-intercom-target="insights-section"
        title={`Tus recomendaciones mensuales de ${currentMonth} 👍🏼`}
        subtitle={<Subtitle isMobileOrTablet={isMobileOrTablet} />}
        subtitleRightElement={
          <Navigation
            handleExternalPrev={handleExternalPrev}
            handleExternalNext={handleExternalNext}
            buttonState={buttonState}
            isVisible={!isMobileOrTablet}
          />
        }
        content={
          taxableEntityFlowsLoading ? (
            <Flex
              css={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                height: '40px',
              }}
            >
              <Loader />
            </Flex>
          ) : (
            <Carousel
              trackProps={{
                // @ts-ignore TODO: Stitches error
                'data-intercom-target': 'insights-carousel',
              }}
              items={carouselItems}
              ref={carouselRef}
              externalControl
              slideStyles={(item) => {
                const flow = item.taxableEntityFlow as TaxableEntityFlow;
                return {
                  ...slideStyles,
                  ...(flow?.status === 'completed' ? seenCss : unseenCss),
                };
              }}
              onButtonStateChange={buttonState.setButtonState}
              onSlideClick={(item, index) => {
                handleFlowSelect(index);
              }}
            />
          )
        }
      />
    </>
  );
}
