import { TaxToPayFlowStep } from 'types/flows';
import toCurrency from '@util/toCurrency';
import { ReactComponent as Img } from './img.svg';
import { ImageContainer, Subtitle, TitleContainer } from './styled';

interface TaxToPayStepContentProps {
  taxToPayFlowStep: TaxToPayFlowStep;
}

export default function TaxToPayStepContent({
  taxToPayFlowStep,
}: TaxToPayStepContentProps) {
  const { state } = taxToPayFlowStep;
  const { to_pay } = state;

  return (
    <>
      <TitleContainer>
        <span data-type="s">
          Tu saldo en impuestos a pagar en <b>Octubre</b>:
        </span>
        <span data-type="l">{toCurrency(+(to_pay || 0))}</span>
      </TitleContainer>
      <ImageContainer>
        <Img />
      </ImageContainer>
      <Subtitle>
        <span data-type="s">
          Esto es un cálculo previo de tu impuesto a pagar
        </span>
        <span data-type="s">al día de hoy para tu próxima declaración.</span>
      </Subtitle>
    </>
  );
}
